// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Complemento de transporte</h2>
                <p>
                    Los complementos de transporte no son una extensión a una factura, sino es una factura normal con campos declarados de una forma particular para que funcione con este propósito de mover bienes.
                </p>
                <p>
                    El uso de este tipo de factura es otorgar a los servicios de transporte un documento que avale el movimiento de los bienes y que no son robados.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/cfdi/<b>traslado</b>/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h3>
                    Campos del Request
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>lugarExpedicion:</b> Código postal del emisor de la factura, este campo sobrescribe el valor del perfil del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>serie:</b> Serie de la factura de transporte.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>folio:</b> Folio de la factura de transporte.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>receptor:</b> Un solo objeto que representa al receptor con las siguientes características.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.nombre:</b> Razón social del receptor de la factura.
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.rfc:</b> RFC del receptor de la factura (Ejemplo muestra RFC de venta a mostrador).
                            </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">receptor</span>.usoCFDI:</b> Siempre P01 para esta factura
                                </p>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <p>
                            <b>conceptos[ ]:</b> Un array de conceptos independientes que integran la factura, solamente se necesitan estos campos.
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.cantidad:</b> Cantidad en número entero o decimal de productos vendidos.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b><span className="clear">conceptos</span>.descripcion:</b> Breve descripción o nombre del producto / servicio.
                            </p>
                            </li>
                        </ul>
                    </li>

                </ul>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, util cuando se quiere usar un procesador de PDFs propio u alguna deconstrucción del contenido de la factura sin tener que parsear el XML.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <p>
                    Las facturas de Transporte no tienen valor fiscal, pero sirven para amparar a un servicio de transporte propio o de un tercero de lo que está trasladando, es muy util para evitar problemas en carreteras.
                </p>
                <SectionNavigate next={null} previous={`/api/referencia/cfdi-complemento-notarios`}/>
            </div>
            <div className="side">
                <CodeBlock title="CFDI Emitir" requests={cfdiScripts.transporte.requests} response={cfdiScripts.transporte.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
